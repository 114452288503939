import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Reports from './ReportsAnalytics';
import Institutes from './InstitutesAnalytics';
import UsersAnalytics from './UsersAnalytics';
import useTitle from '../../hooks/useTitle';

const Analytics = () => {
    useTitle(`telerapp - System Analytics`);

    const [selectedLink, setSelectedLink] = useState('Reports');
    const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());

    const handleLinkClick = (link) => {
        if (link === 'Monitor-aws') {
            window.open('https://cloudwatch.amazonaws.com/dashboard.html?dashboard=telerappai&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTYzNzQyMzE5OTY1MSIsIlUiOiJ1cy1lYXN0LTFfcWN6WGFtYlhIIiwiQyI6IjdwazJzdGJ2Z2hobDhkODI4bXNxMDFlMHZsIiwiSSI6InVzLWVhc3QtMTozM2ViNDVhMi01ZjYzLTQzZTMtYmQ2Yy0yNWQ5Yjg4OTg3ODUiLCJPIjoiYXJuOmF3czppYW06OjYzNzQyMzE5OTY1MTpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1QdWJsaWNSZWFkT25seUFjY2Vzcy1EM1hEV0RVMSIsIk0iOiJQdWJsaWMifQ==&start=PT3H&end=null', '_blank');
        } else {
            setSelectedLink(link);
        }
    };

    const handleDateChange = (type, date) => {
        if (type === 'start') {
            setStartDate(date);
        } else if (type === 'end') {
            setEndDate(date);
        }
    };

    return (
        <Container fluid style={{ paddingTop: '6rem', paddingBottom: '5rem' }}>
            <Row className="justify-content-md-center">
                <Col xs={12} className="text-center mb-4">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div key="3" className="link-item mx-2 mb-2">
                            <Link onClick={() => handleLinkClick('Reports')} className="custom-link">Reports</Link>
                        </div>
                        <div key="4" className="link-item mx-2 mb-2">
                            <Link onClick={() => handleLinkClick('Users')} className="custom-link">Users</Link>
                        </div>
                        <div key="2" className="link-item mx-2 mb-2">
                            <Link onClick={() => handleLinkClick('Institutes')} className="custom-link">Institutes</Link>
                        </div>
                        <div key="1" className="link-item mx-2 mb-2">
                            <Link onClick={() => handleLinkClick('Monitor-aws')} className="custom-link">
                                Monitor-aws <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col xs={12} className="content-column">
                    {selectedLink === 'Reports' && (
                        <Reports startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
                    )}
                    {selectedLink === 'Institutes' && (
                        <Institutes startDate={startDate} endDate={endDate} />
                    )}
                    {selectedLink === 'Users' && (
                        <UsersAnalytics startDate={startDate} endDate={endDate} />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Analytics;

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Toast } from 'react-bootstrap';
import { useCreateTxtFileMutation, useDeleteTxtFileMutation, useGetFilesQuery, useGetFileContentQuery } from './tempApiSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useTitle from '../../hooks/useTitle';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
    ],
};

const TempOps = () => {
    useTitle(`telerapp - Templates Manager`);

    const [createFilename, setCreateFilename] = useState('');
    const [deleteFilename, setDeleteFilename] = useState('');
    const [content, setContent] = useState('');
    const [createTxtFile, { isLoading: isCreating }] = useCreateTxtFileMutation();
    const [deleteTxtFile, { isLoading: isDeleting }] = useDeleteTxtFileMutation();
    const [selectedFile, setSelectedFile] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const quillRef = useRef(null);

    const handleQuillChange = (html) => {
        setContent(html);
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            await createTxtFile({ filename: createFilename, content }).unwrap();
            setToastMessage({ text: 'Template created successfully', isSuccess: true });
            setShowToast(true);
            setCreateFilename('');
            setContent('');
            window.location.reload();
        } catch (error) {
            setToastMessage({ text: `Failed to create template: ${error?.data?.message}`, isSuccess: false });
            setShowToast(true);
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm("Are you sure you want to delete this template?");
        if (confirmDelete) {
            try {
                await deleteTxtFile(deleteFilename).unwrap();
                setToastMessage({ text: 'Template deleted successfully', isSuccess: true });
                setShowToast(true);
                setDeleteFilename('');
                window.location.reload();
            } catch (error) {
                setToastMessage({ text: `Failed to delete template: ${error?.data?.message}`, isSuccess: false });
                setShowToast(true);
            }
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFileSelect = (e) => {
        const filename = e.target.value;
        setSelectedFile(filename);
        setSearchTerm('');
    };

    const { data: files } = useGetFilesQuery();
    const filteredFiles = files?.filter(file => file.toLowerCase().includes(searchTerm.toLowerCase()));

    const {
        data: fileContent,
        error: fileContentError,
        isLoading: fileContentLoading,
    } = useGetFileContentQuery(selectedFile, { skip: !selectedFile });

    useEffect(() => {
        if (fileContent && !fileContentLoading && !fileContentError) {
            setContent(fileContent.data);
        }
    }, [fileContent, fileContentLoading, fileContentError, selectedFile]);

    return (
        <>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
                position: 'fixed',
                top: '50%',
                right: '50px',
                width: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1050,
                color: toastMessage.isSuccess ? 'green' : 'red'
            }}>
                <Toast.Header>
                    <strong className="mr-auto">{toastMessage.isSuccess ? 'Successful' : 'Error'}</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage.text}</Toast.Body>
            </Toast>
            <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={10} lg={10}>
                        <label style={{ fontWeight: 'bold' }}>Create Template : </label>
                        <Card className="mb-3">
                            <Card.Body style={{
                                height: '550px',
                            }}>
                                <form onSubmit={handleCreate}>
                                    <input
                                        type="text"
                                        placeholder='Template name'
                                        title="Template name"
                                        style={{ borderRadius: '6px', width: '50%', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                        value={createFilename}
                                        onChange={(e) => setCreateFilename(e.target.value)}
                                        required
                                    />
                                    <ReactQuill
                                        theme="snow"
                                        value={content}
                                        onChange={handleQuillChange}
                                        modules={modules}
                                        ref={quillRef}
                                        className='react-quill1'
                                        title="Template data"
                                    />
                                    <button type="submit" title="Create template" className="template-Button1" disabled={isCreating}>
                                        {isCreating ? 'Creating...' : 'Create'}
                                    </button>
                                </form>
                            </Card.Body>
                        </Card>

                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <input
                                type="text"
                                title="Search template"
                                placeholder="Search for a template"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ borderRadius: '6px', padding: '4px 8px', width: '40%', border: '2px solid #ccc', marginRight: '10px' }}
                            />
                            <select
                                value={selectedFile || ''}
                                onChange={handleFileSelect}
                                className='template-select'
                                title="Select template"
                                style={{ width: '30%' }}
                            >
                                <option value="">Select a template</option>
                                {filteredFiles?.map(file => {
                                    const fileNameWithoutExtension = file.split('.').slice(0, -1).join('.');
                                    return (
                                        <option key={file} value={file}>{fileNameWithoutExtension}</option>
                                    );
                                })}
                            </select>
                        </div>
                        {searchTerm && !filteredFiles.length && (
                            <div style={{ color: '#a30000cd', marginTop: '5px' }}>
                                No template found
                            </div>
                        )}
                        {searchTerm && filteredFiles?.length > 0 && (
                            <div className="autocomplete-dropdown">
                                {filteredFiles?.map(file => {
                                    const fileNameWithoutExtension = file.split('.').slice(0, -1).join('.');
                                    return (
                                        <div
                                            key={file}
                                            onClick={() => handleFileSelect({ target: { value: file } })}
                                            className="autocomplete-suggestion"
                                        >
                                            {fileNameWithoutExtension}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <label style={{ fontWeight: 'bold', marginTop: '10px' }}>Delete Template : </label>
                        <Card className="mb-3">
                            <Card.Body>
                                <form onSubmit={handleDelete}>
                                    <input
                                        type="text"
                                        placeholder='Template name'
                                        title='Template name'
                                        style={{ borderRadius: '6px', width: '50%', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                        value={deleteFilename}
                                        onChange={(e) => setDeleteFilename(e.target.value)}
                                        required
                                    />
                                    <button type="submit" title="Delete template" className="template-Button2" style={{ marginTop: '5px' }} disabled={isDeleting}>
                                        {isDeleting ? 'Deleting...' : 'Delete'}
                                    </button>
                                </form>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default TempOps;

import { apiSlice } from "../../app/api/apiSlice"

export const logApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLogFiles: builder.query({
            query: () => '/backend/log/logs',
        }),
        getLogFileContent: builder.query({
            query: (fileName) => `/backend/log/logs/${fileName}`,
        }),
    }),
})

export const {
    useGetLogFilesQuery,
    useGetLogFileContentQuery,
} = logApiSlice

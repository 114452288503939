import React, { useState } from 'react';
import { Container, Row, Col, Toast, Button } from 'react-bootstrap';
import { useAddQuestionAnswerMutation, useEditQuestionAnswerMutation, useDeleteQuestionAnswerMutation, useGetLatestQuestionsAnswersQuery } from './qaApiSlice';
import useTitle from '../../hooks/useTitle';

const QAManagementPage = () => {
    useTitle(`telerapp - Q&A Manager`);

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [editId, setEditId] = useState('');
    const [editQuestion, setEditQuestion] = useState('');
    const [editAnswer, setEditAnswer] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const [addQuestionAnswer, { isLoading: isAdding }] = useAddQuestionAnswerMutation();
    const [editQuestionAnswer, { isLoading: isEditing }] = useEditQuestionAnswerMutation();
    const [deleteQuestionAnswer] = useDeleteQuestionAnswerMutation();

    const { data: questionsAnswers, refetch } = useGetLatestQuestionsAnswersQuery();

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            await addQuestionAnswer({ question, answer }).unwrap();
            setToastMessage({ text: 'Question and Answer added successfully', isSuccess: true });
            setShowToast(true);
            setQuestion('');
            setAnswer('');
            refetch();
        } catch (error) {
            setToastMessage({ text: `Failed to add question and answer: ${error.message}`, isSuccess: false });
            setShowToast(true);
        }
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            await editQuestionAnswer({ id: editId, question: editQuestion, answer: editAnswer }).unwrap();
            setToastMessage({ text: 'Question and Answer updated successfully', isSuccess: true });
            setShowToast(true);
            setEditId('');
            setEditQuestion('');
            setEditAnswer('');
            refetch();
        } catch (error) {
            setToastMessage({ text: `Failed to update question and answer: ${error.message}`, isSuccess: false });
            setShowToast(true);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this question and answer?");
        if (confirmDelete) {
            try {
                await deleteQuestionAnswer(id).unwrap(); // Pass id directly as integer
                setToastMessage({ text: 'Question and Answer deleted successfully', isSuccess: true });
                setShowToast(true);
                refetch();
            } catch (error) {
                setToastMessage({ text: `Failed to delete question and answer: ${error.message}`, isSuccess: false });
                setShowToast(true);
            }
        }
    };

    const selectEditQA = (qa) => {
        setEditId(qa.id);
        setEditQuestion(qa.question);
        setEditAnswer(qa.answer);
    };

    const toggleAnswer = (index) => {
        setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
    };

    return (
        <>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
                position: 'fixed',
                top: '50%',
                right: '50px',
                width: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1050,
                color: toastMessage.isSuccess ? 'green' : 'red'
            }}>
                <Toast.Header>
                    <strong className="mr-auto">{toastMessage.isSuccess ? 'Successful' : 'Error'}</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage.text}</Toast.Body>
            </Toast>
            <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={10} lg={8} style={{ maxWidth: '100%' }}>
                        <h3 className="text-center mb-4">Manage Questions and Answers</h3>
                        <div className="mb-5">
                            <label style={{ fontWeight: 'bold' }}>Add Question and Answer:</label>
                            <div className="mb-3 p-3" style={{ background: '#f8f9fa', borderRadius: '6px', border: '1px solid #ccc' }}>
                                <form onSubmit={handleCreate} className="d-flex flex-column">
                                    <input
                                        type="text"
                                        placeholder="Question"
                                        title="Question"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginBottom: '10px' }}
                                        value={question}
                                        onChange={(e) => setQuestion(e.target.value)}
                                        required
                                    />
                                    <textarea
                                        type="text"
                                        placeholder="Answer"
                                        title="Answer"
                                        style={{ borderRadius: '6px', padding: '20px 8px', border: '2px solid #ccc', marginBottom: '10px' }}
                                        value={answer}
                                        onChange={(e) => setAnswer(e.target.value)}
                                        required
                                    />
                                    <button type="submit" title="Add QA" className="qa-Button btn" style={{ marginTop: '5px', width: '35%', color: 'white', background: '#008080cd' }} disabled={isAdding}>
                                        {isAdding ? 'Adding...' : 'Add'}
                                    </button>
                                </form>
                            </div>

                            <label style={{ fontWeight: 'bold' }}>Edit Question and Answer:</label>
                            <div className="mb-3 p-3" style={{ background: '#f8f9fa', borderRadius: '6px', border: '1px solid #ccc' }}>
                                <form onSubmit={handleEdit} className="d-flex flex-column">
                                    <input
                                        type="text"
                                        placeholder="ID"
                                        title="ID"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginBottom: '10px' }}
                                        value={editId || ''}
                                        onChange={(e) => setEditId(e.target.value)}
                                        readOnly
                                        required
                                    />
                                    <input
                                        type="text"
                                        placeholder="New Question"
                                        title="New Question"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginBottom: '10px' }}
                                        value={editQuestion}
                                        onChange={(e) => setEditQuestion(e.target.value)}
                                        required
                                    />
                                    <textarea
                                        type="text"
                                        placeholder="New Answer"
                                        title="New Answer"
                                        style={{ borderRadius: '6px', padding: '20px 8px', border: '2px solid #ccc', marginBottom: '10px' }}
                                        value={editAnswer}
                                        onChange={(e) => setEditAnswer(e.target.value)}
                                        required
                                    />
                                    <button type="submit" title="Edit QA" className="qa-Button btn" style={{ marginTop: '5px', width: '35%', color: 'white', background: '#717378cd' }} disabled={isEditing}>
                                        {isEditing ? 'Editing...' : 'Edit'}
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div className="qa-list">
                            <ul className="faq-list" title="All Questions and Answers">
                                {questionsAnswers?.map((qa, index) => (
                                    <li key={qa.id} style={{ marginBottom: '10px', listStyleType: 'none' }}>
                                        <div className="faq-question" onClick={() => toggleAnswer(index)} style={{ cursor: 'pointer', padding: '10px', background: '#f8f9fa', borderRadius: '6px', border: '1px solid #ccc' }}>
                                            <strong>Q:</strong> {qa.question}
                                            <div style={{ textAlign: 'right' }}>
                                                <Button variant="danger" onClick={() => handleDelete(qa.id)} style={{ marginRight: '10px' }}>Delete</Button>
                                                <Button variant="secondary" onClick={() => selectEditQA(qa)}>Edit</Button>
                                            </div>
                                            <button className="faq-toggle" aria-label="Toggle answer" style={{ float: 'right', background: 'none', border: 'none' }}>
                                                <i className={`fas fa-chevron-${activeQuestionIndex === index ? 'up' : 'down'}`} />
                                            </button>
                                        </div>
                                        <div className="faq-answer" style={{ display: activeQuestionIndex === index ? 'block' : 'none', padding: '10px', background: '#ffffff', borderRadius: '6px', border: '1px solid #ccc', marginTop: '5px' }}>
                                            <p><strong>A:</strong> {qa.answer}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default QAManagementPage;

import { store } from '../../app/store'
import { reportsApiSlice } from '../reports/reportsApiSlice'
import { usersApiSlice } from '../users/usersApiSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {

    useEffect(() => {

        store.dispatch(reportsApiSlice.util.prefetch('getReports', 'reportsList', { force: true }))
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))

    }, [])

    return <Outlet />
}
export default Prefetch
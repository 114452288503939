import React, { useState } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { useGetLatestQuestionsAnswersQuery, useSearchQuestionsAnswersMutation } from './qaApiSlice';
import useTitle from '../../hooks/useTitle';

const SearchQAPage = () => {
    useTitle(`telerapp - Latest Q&A`);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { data: latestQuestionsAnswers } = useGetLatestQuestionsAnswersQuery();
    const [searchQuestionsAnswers, { isLoading: isSearching }] = useSearchQuestionsAnswersMutation();
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const handleSearch = async () => {
        try {
            const response = await searchQuestionsAnswers(searchQuery).unwrap();
            setSearchResults(response);
            setShowSearchResults(true);
        } catch (error) {
            setErrorMessage('No matching results found.');
            setSearchResults([]);
            setShowSearchResults(false);
        }
    };

    const handleResetSearch = () => {
        setSearchQuery('');
        setSearchResults(null);
        setShowSearchResults(false);
        setErrorMessage('');
        setActiveQuestionIndex(null);
    };

    const toggleAnswer = (index) => {
        setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
    };

    return (
        <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={10} lg={8} style={{ maxWidth: '100%' }}>
                    <h3 className="text-center mb-4">Search Questions and Answers</h3>
                    <div className="mb-5">
                        <div className="d-flex flex-column flex-md-row mb-3">
                            <input
                                type="text"
                                placeholder="Search query"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="flex-grow-1 form-control mb-2 mb-md-0"
                                style={{ borderRadius: '6px', border: '2px solid #ccc', marginRight: '10px' }}
                            />
                            <Button onClick={handleSearch} disabled={isSearching} style={{ marginRight: '5px' }} className="mb-2 mb-md-0">
                                {isSearching ? 'Searching...' : 'Search'}
                            </Button>
                            <Button variant="secondary" onClick={handleResetSearch} className="ml-md-2">
                                Reset
                            </Button>
                        </div>
                        {errorMessage && (
                            <Alert variant="warning">
                                {errorMessage}
                            </Alert>
                        )}
                    </div>
                    <ul className="faq-list" style={{ width: '100%' }} title={showSearchResults ? "Search Results" : "Latest Questions and Answers"}>
                        {(showSearchResults ? searchResults : latestQuestionsAnswers)?.map((qa, index) => (
                            <li key={qa.id} style={{ marginBottom: '10px', listStyleType: 'none' }}>
                                <div className="faq-question" onClick={() => toggleAnswer(index)} style={{ cursor: 'pointer', padding: '10px', background: '#f8f9fa', borderRadius: '6px', border: '1px solid #ccc' }}>
                                    <strong>Q: {qa.question}</strong>
                                    <button className="faq-toggle" aria-label="Toggle answer" style={{ float: 'right', background: 'none', border: 'none' }}>
                                        <i className={`fas fa-chevron-${activeQuestionIndex === index ? 'up' : 'down'}`} />
                                    </button>
                                </div>
                                <div className="faq-answer" style={{ display: activeQuestionIndex === index ? 'block' : 'none', padding: '10px', background: '#ffffff', borderRadius: '6px', border: '1px solid #ccc', marginTop: '5px' }}>
                                    <p>A: {qa.answer}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default SearchQAPage;

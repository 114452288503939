import React, { useState, useEffect } from 'react';
import { useFetchMessagesQuery, useSaveMessageMutation } from './messageApiSlice';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { faPaperPlane, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ChatBox({ closeChat }) {

    const { id } = useParams()
    const { username } = useAuth()

    const [message, setMessage] = useState('');
    const { data: messages = [], refetch } = useFetchMessagesQuery(id);
    const [saveMessage, { isLoading }] = useSaveMessageMutation();

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 2000);
        const messageBox = document.querySelector('.message-list');
        messageBox.scrollTop = messageBox.scrollHeight;
        return () => clearInterval(interval);
    }, [refetch, messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (message.trim() === '') return;
        await saveMessage({ id, username, message: message });
        setMessage('');
        refetch();
    };

    return (
        <Container className="chat-container">
            <Row className="justify-content-md-center">
                <Col xs={12} md={8} lg={6}>
                    <Card className="mb-3">
                        <Card.Body>
                            <div className="message-box">
                                <ul className="message-list">
                                    {messages.map((msg, index) => {
                                        const [msgUsername, content] = msg.split(': ');
                                        return (
                                            <li key={index} className={msgUsername === username ? 'left-align' : 'right-align'}>
                                                {msgUsername === username ? `${msgUsername}: ${content}` : `${content} : ${msgUsername}`}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <form onSubmit={handleSubmit} className="message-form">
                                <input
                                    type="text"
                                    title="Enter message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    disabled={isLoading}
                                    className="message-input"
                                    style={{ width: '70%' }}
                                />
                                <button type="submit" style={{ width: '15%' }} title="Send" className="send-button" disabled={isLoading}>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                                <button onClick={closeChat} style={{ width: '15%' }} title="Notification" className="send-button">
                                    <FontAwesomeIcon icon={faBell} />
                                </button>

                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default ChatBox;

import React, { useState } from 'react';
import { Container, Row, Col, Card, Toast } from 'react-bootstrap'; // Assuming you're using Bootstrap
import { useSendFeedbackMutation } from '../feedback/feedbackApiSlice';
import useTitle from '../../hooks/useTitle';

function FeedbackForm() {
    useTitle(`telerapp - Send Feedback`);

    const [userid, setUserid] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [sendFeedback, { isLoading }] = useSendFeedbackMutation();

    const sendMail = () => {
        sendFeedback({ userid, subject, message })
            .unwrap()
            .then(() => {
                setToastMessage({ text: 'Email sent successfully', isSuccess: true });
                setShowToast(true);
            })
            .catch(() => {
                setToastMessage({ text: 'Failed to send email', isSuccess: false });
                setShowToast(true);
            });
    };

    return (
        <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
            <Row className="justify-content-md-center">

                <Col xs={12} md={8} lg={6}>
                    <h2 style={{ textAlign: 'center' }}>Feedback</h2>
                    <br />
                    <Card style={{ border: 'none' }} className="mb-3">
                        <Card.Body>
                            <form>
                                <input
                                    type="text"
                                    placeholder="UserID"
                                    title="Username"
                                    value={userid}
                                    className='feedback-input'
                                    onChange={(e) => setUserid(e.target.value)}
                                    required
                                />

                                <input
                                    type="text"
                                    placeholder="Subject"
                                    title="Subject"
                                    value={subject}
                                    className='feedback-input'
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                                />

                                <textarea
                                    placeholder="Feedback"
                                    title="Feedback"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    style={{ borderRadius: '6px', resize: 'none', padding: '4px 8px', border: '2px solid #ccc', marginBottom: '10px', height: '200px', width: '100%' }}
                                    required
                                />

                                <button
                                    onClick={sendMail}
                                    disabled={isLoading}
                                    className='feedback-btn'
                                    title="Send"
                                >
                                    {isLoading ? 'Sending...' : `Send`}
                                </button>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
                position: 'fixed',
                top: '50%',
                right: '50px',
                width: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1050,
                color: toastMessage.isSuccess ? 'green' : 'red'
            }}>
                <Toast.Header>
                    <strong className="mr-auto">{toastMessage.isSuccess ? 'Success' : 'Error'}</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage.text}</Toast.Body>
            </Toast>
        </Container>
    );
}

export default FeedbackForm;

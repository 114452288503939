import { apiSlice } from "../../app/api/apiSlice";

export const qaApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addQuestionAnswer: builder.mutation({
            query: ({ question, answer }) => ({
                url: '/backend/qa/addqa',
                method: 'POST',
                body: { question, answer }
            }),
            invalidatesTags: [
                { type: 'QA', id: "LIST" }
            ]
        }),
        editQuestionAnswer: builder.mutation({
            query: ({ id, question, answer }) => ({
                url: '/backend/qa/editqa',
                method: 'PUT',
                body: { id, question, answer }
            }),
            invalidatesTags: [
                { type: 'QA', id: "LIST" }
            ]
        }),
        getLatestQuestionsAnswers: builder.query({
            query: () => '/backend/qa/latestqa',
            providesTags: (result = [], error, arg) => [
                { type: 'QA', id: "LIST" },
                ...result.map(({ id }) => ({ type: 'QA', id }))
            ],
        }),
        getLatestFiveQuestionsAnswers: builder.query({
            query: () => '/backend/qa/latestfiveqa',
            providesTags: (result = [], error, arg) => [
                { type: 'QA', id: "LIST" },
                ...result.map(({ id }) => ({ type: 'QA', id }))
            ],
        }),
        searchQuestionsAnswers: builder.mutation({
            query: (query) => ({
                url: '/backend/qa/searchqa',
                method: 'POST',
                body: { query }
            }),
        }),
        deleteQuestionAnswer: builder.mutation({
            query: (id) => ({
                url: `/backend/qa/deleteqa`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: [
                { type: 'QA', id: "LIST" }
            ]
        }),
    }),
});

export const {
    useAddQuestionAnswerMutation,
    useEditQuestionAnswerMutation,
    useGetLatestQuestionsAnswersQuery,
    useGetLatestFiveQuestionsAnswersQuery,
    useSearchQuestionsAnswersMutation,
    useDeleteQuestionAnswerMutation,
} = qaApiSlice;

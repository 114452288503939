import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout';
import Public from './components/Public';
import Login from './features/auth/Login';
import DashLayout from './components/DashLayout';
import Welcome from './features/auth/Welcome';
import ReportsList from './features/reports/ReportsList';
import UsersList from './features/users/UsersList';
import EditUser from './features/users/EditUser';
import NewUserForm from './features/users/NewUserForm';
import EditReport from './features/reports/EditReport';
import NewReport from './features/reports/NewReport';
import Prefetch from './features/auth/Prefetch';
import PersistLogin from './features/auth/PersistLogin';
import { ROLES } from './config/roles';
import RequireAuth from './features/auth/RequireAuth';
import NotFound404 from './components/NotFound404'
import useTitle from './hooks/useTitle';
import TempOps from './features/temp/TempOps';
import InstitutePage from './features/insti/InstitutePage';
import LogOps from './features/logs/LogOps';
import FeedbackForm from './features/feedback/FeedbackForm';
import ReportsByDate from './features/reports/ReportsByDate';
import QAManagementPage from './features/qa/QAManagementPage';
import SearchQAPage from './features/qa/SearchQAPage';
import FileViewerPage from './features/fileViewer/FileViewerPage';
import Analytics from './features/analytics/Analytics';

function App() {

  useTitle('telerapp - Augmenting Diagnostic Intelligence')

  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        {/* public routes */}

        <Route index element={<Public />} />

        <Route path='login' element={<Login />} />

        {/* Protected Routes */}

        <Route element={<PersistLogin />}>

          <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
            <Route element={<Prefetch />} >

              <Route path='dash' element={<DashLayout />}>

                <Route index element={<Welcome />} />

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path='users'>
                    <Route index element={<UsersList />} />
                    <Route path=':id' element={<EditUser />} />
                    <Route path='new' element={<NewUserForm />} />
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Manager]} />}>
                  <Route path='institutes' element={<InstitutePage />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path='logs' element={<LogOps />} />
                </Route>

                <Route path='report'>
                  <Route index element={<ReportsList />} />
                  <Route path=':id' element={<EditReport />} />
                  <Route path='new' element={<NewReport />} />
                  <Route path='archive' element={<ReportsByDate />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Consultant, ROLES.Manager]} />}>
                  <Route path='templates' element={<TempOps />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Manager]} />}>
                  <Route path='manage-qa' element={<QAManagementPage />} />
                </Route>

                <Route>
                  <Route path='file-viewer' element={<FileViewerPage />} />
                </Route>

                <Route>
                  <Route path='qa' element={<SearchQAPage />} />
                </Route>

                <Route>
                  <Route path='feedback' element={<FeedbackForm />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path='analytics' element={<Analytics />} />
                </Route>

              </Route>

            </Route>
          </Route>

        </Route>

        <Route path="*" element={<NotFound404 />} />

      </Route>
    </Routes>
  );
}

export default App;

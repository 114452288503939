import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import logo from '../img/logo-main.png'
import useAuth from '../hooks/useAuth'
import { faUser, faPlus, faBook, faUsers, faArrowRightFromBracket, faFileContract } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashHeader = () => {
    const { isAdmin, annotationviewer } = useAuth()

    const navigate = useNavigate()

    const [logoutSuccess, setLogoutSuccess] = useState(false)

    const [sendLogout, {
        isSuccess
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess || logoutSuccess) {
            navigate('/');
        }
    }, [isSuccess, logoutSuccess, navigate]);

    const onNewReportClicked = () => navigate('/dash/report/new')
    const onNewUserClicked = () => navigate('/dash/users/new')
    const onReportsClicked = () => navigate('/dash/report')
    const onUsersClicked = () => navigate('/dash/users')

    const onAnnotationClicked = () => {
        window.open('http://3.135.214.96:8000/?key=teler@pp24', '_blank');
    };

    const logoutButton = () => {
        sendLogout();
        setLogoutSuccess(true);
        localStorage.removeItem('myOriginated');
    }

    const content = (
        <>
            <Navbar collapseOnSelect expand="lg" className='fixed-top bg-body-tertiary shadow'>
                <Container>
                    <Navbar.Brand>
                        <Link to="/dash" title="Dashboard" className='navbar-brand text-success fw-semibold'>
                            <img src={logo} alt='logo' width="100" height="25" />
                        </Link>
                    </Navbar.Brand>
                    {/* <>
                            <strong className="me-3"> UserID : {username} </strong>
                            <strong> Role : {status} </strong>
                        </> */}
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto justify-content-end w-100'>

                            <Nav.Link onClick={onReportsClicked} eventKey='1' className='text-uppercase'><FontAwesomeIcon icon={faBook} /> WorkList</Nav.Link>
                            <Nav.Link onClick={onNewReportClicked} eventKey='2' className='text-uppercase'><FontAwesomeIcon icon={faPlus} /> New Report</Nav.Link>

                            {isAdmin && <><Nav.Link onClick={onNewUserClicked} eventKey='3' className='text-uppercase'><FontAwesomeIcon icon={faUser} /> Add User</Nav.Link>
                                <Nav.Link onClick={onUsersClicked} eventKey='4' className='text-uppercase'><FontAwesomeIcon icon={faUsers} /> All Users</Nav.Link> </>}

                            {annotationviewer && <Nav.Link onClick={onAnnotationClicked} eventKey='5' className='text-uppercase'>
                                <FontAwesomeIcon icon={faFileContract} /> Viewer
                            </Nav.Link>}

                            <Nav.Link onClick={logoutButton} title="Logout" eventKey='6' className='text-uppercase' style={{ marginLeft: '20px' }}><FontAwesomeIcon icon={faArrowRightFromBracket} style={{ color: "#a30000cd" }} /></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )

    return content
}
export default DashHeader
import { apiSlice } from "../../app/api/apiSlice";

const analyticsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRDSStorageInfo: builder.query({
            query: () => ({
                url: '/backend/analytics/rds-storage',
                method: 'GET',
            }),
        }),
        getReportsByModalityAndDateRange: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-modality-and-date-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getReportsByPriorityAndDateRange: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-priority-and-date-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getApprovedReportsByModalityAndDateRange: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-modality-approved-and-date-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getReportsByCompletedStatusAndDateRange: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-status-and-date-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getReportsByModalityAndInstitute: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-modality-institute-and-date-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getReportsByDateAnalytics: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-number-and-date-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getReportsByWeekPerform: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-week-and-date-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getReportsByUserModality: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-user-and-modality-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
        getByUserPerformance: builder.query({
            query: ({ username }) => ({
                url: '/backend/analytics/by-user-performance-range',
                method: 'POST',
                body: { username },
            }),
        }),
        getTatAverage: builder.query({
            query: ({ startDate, endDate }) => ({
                url: '/backend/analytics/by-tat-range',
                method: 'POST',
                body: { startDate, endDate },
            }),
        }),
    }),
});

export const {
    useGetRDSStorageInfoQuery,
    useGetReportsByModalityAndDateRangeQuery,
    useGetReportsByPriorityAndDateRangeQuery,
    useGetApprovedReportsByModalityAndDateRangeQuery,
    useGetReportsByCompletedStatusAndDateRangeQuery,
    useGetReportsByModalityAndInstituteQuery,
    useGetReportsByDateAnalyticsQuery,
    useGetReportsByWeekPerformQuery,
    useGetReportsByUserModalityQuery,
    useGetByUserPerformanceQuery,
    useGetTatAverageQuery,
} = analyticsApiSlice;
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import useTitle from '../../hooks/useTitle';

const FileViewerPage = () => {
    useTitle(`telerapp - File Viewer`);

    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [fileContent, setFileContent] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        setFileType(file.type);

        const reader = new FileReader();
        reader.onload = () => {
            if (file.type === 'text/plain') {
                setFileContent(reader.result);
            } else {
                setFileContent('');
            }
        };
        if (file.type === 'text/plain') {
            reader.readAsText(file);
        }
    };

    const renderFileViewer = () => {
        switch (fileType) {
            case 'application/pdf':
                return (
                    <embed
                        src={URL.createObjectURL(file)}
                        type="application/pdf"
                        style={{ width: '100%', height: '100vh', resize: 'none', border: 'none', padding: '10px' }}
                    />
                );
            case 'text/plain':
                return (
                    <textarea
                        value={fileContent}
                        onChange={(e) => setFileContent(e.target.value)}
                        style={{ width: '100%', height: '100vh', resize: 'none', border: 'none', padding: '10px' }}
                    />
                );
            case 'video/mp4':
                return (
                    <div style={{ position: 'relative', paddingTop: '56.25%', /* 16:9 aspect ratio */ }}>
                        <video controls style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                            <source src={URL.createObjectURL(file)} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                );
            default:
                return <p>Unsupported file type</p>;
        }
    };

    return (
        <Container fluid style={{ paddingTop: '6rem', paddingBottom: '7rem', minHeight: '100vh' }}>
            <Row className="justify-content-center align-items-center" style={{ height: '100%' }}>
                <Col xs={12} md={8} lg={6} className="d-flex flex-column align-items-center">
                    <label style={{ fontWeight: 'bold', marginBottom: '1rem' }}>Select a file to view:</label>
                    <input type="file" onChange={handleFileChange} accept=".pdf,.txt,.mp4" style={{ marginBottom: '1rem' }} />
                    {file && (
                        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                            {renderFileViewer()}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default FileViewerPage;

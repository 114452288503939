import React from 'react'
import useTitle from '../hooks/useTitle'
import { Link } from 'react-router-dom'

const NotFound404 = () => {
    useTitle('telerapp - 404 page not found')

    return (
        <div id="error-page" className="container">
            <div className="content">
                <h4>404</h4>
                <h4 data-text="Opps! Page not found">
                    Opps! Page not found
                </h4>
                <p>
                    Sorry, the page you're looking for doesn't exist.
                </p>
                <Link to="/dash" title="Dashboard" className='link2'>Return to Dashboard</Link>
            </div>
        </div>
    )
}

export default NotFound404

import { Link } from 'react-router-dom'
import logo from '../img/logo-main.png'

const Public = () => {
    const content = (
        <div className='home-page'>
            <header className='h-100 min-vh-100 d-flex align-items-center text-light shadow'>
                <div className='container'>
                    <div className='public-logo'>
                        <img src={logo} className='logo' alt='logo' width="100" height="25" /><p>telerapp.llc</p>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-6 d-flex flex-column align-items-center text-center'>
                            <h1>telerapp</h1>
                            <br />
                            <h3 className='shadow'>
                                Augmenting Diagnostic Intelligence For Saving Lives…
                            </h3>
                            <br />
                            <Link to="/login" title="Login" className='link'>Member Login</Link>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
    return content
}
export default Public

import { apiSlice } from "../../app/api/apiSlice"

export const tempApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createTxtFile: builder.mutation({
            query: ({ filename, content }) => ({
                url: '/backend/temp/files',
                method: 'POST',
                body: { filename, content }
            }),
            invalidatesTags: [
                { type: 'Temp', id: "LIST" }
            ]
        }),
        deleteTxtFile: builder.mutation({
            query: (filename) => ({
                url: `/backend/temp/files/${filename}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'Temp', id: "LIST" }
            ]
        }),
        getFiles: builder.query({
            query: () => '/backend/temp/files',
        }),
        getFileContent: builder.query({
            query: (fileName) => `/backend/temp/files/${fileName}`,
        }),
    }),
})

export const {
    useCreateTxtFileMutation,
    useDeleteTxtFileMutation,
    useGetFilesQuery,
    useGetFileContentQuery,
} = tempApiSlice

import { apiSlice } from "../../app/api/apiSlice";

export const messageApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchMessages: builder.query({
            query: (id) => `/backend/msg/messages/${id}`,
        }),
        saveMessage: builder.mutation({
            query: ({ id, username, message }) => ({
                url: '/backend/msg/messages',
                method: 'POST',
                body: { id, username, message }
            }),
        }),
    }),
})

export const {
    useFetchMessagesQuery,
    useSaveMessageMutation,
} = messageApiSlice;

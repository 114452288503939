import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Form } from 'react-bootstrap';
import PulseLoader from 'react-spinners/PulseLoader';
import { useGetReportsByUserModalityQuery, useGetByUserPerformanceQuery } from './analyticsApiSlice';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UsersAnalytics = () => {
    const calculateDefaultDates = () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
        return { startDate, endDate };
    };

    const [dateRange, setDateRange] = useState(calculateDefaultDates());
    const [selectedUser, setSelectedUser] = useState('');
    const [userPerformanceData, setUserPerformanceData] = useState(null);

    const handleDateChange = (field, date) => {
        setDateRange(prevState => ({
            ...prevState,
            [field]: date,
        }));
    };

    const {
        data: reportsByUserModality,
        isLoading: isReportsByUserModalityLoading,
        isError: isReportsByUserModalityError,
    } = useGetReportsByUserModalityQuery({ startDate: dateRange.startDate.toISOString().split('T')[0], endDate: dateRange.endDate.toISOString().split('T')[0] });

    const {
        data: performanceData,
        isLoading: isPerformanceLoading,
        isError: isPerformanceError,
    } = useGetByUserPerformanceQuery({ username: selectedUser }, { skip: !selectedUser });

    useEffect(() => {
        if (performanceData) {
            setUserPerformanceData(performanceData);
        }
    }, [performanceData]);

    const handleIconClick = (username) => {
        setSelectedUser(username);
    };

    const renderTable = (data) => {
        const modalities = ['MR', 'CR/DX', 'US', 'MG', 'CT'];
        let totalReports = 0;
        let totalRVU = 0;

        const tableRows = Object.entries(data).map(([user, modalitiesData]) => {
            const total = modalities.reduce((sum, modality) => sum + (parseInt(modalitiesData[modality]) || 0), 0);
            totalReports += total;

            const rvu =
                (parseInt(modalitiesData['CR/DX']) || 0) * 0.3 +
                (parseInt(modalitiesData['US']) || 0) * 0.7 +
                (parseInt(modalitiesData['CT']) || 0) * 1.5 +
                (parseInt(modalitiesData['MR']) || 0) * 1.4 +
                (parseInt(modalitiesData['MG']) || 0) * 0.7;
            totalRVU += rvu;

            return (
                <tr key={user}>
                    <td>
                        {user} <FontAwesomeIcon icon={faChartLine} onClick={() => handleIconClick(user)} style={{ cursor: 'pointer' }} />
                    </td>
                    {modalities.map(modality => (
                        <td key={modality}>{modalitiesData[modality] || 0}</td>
                    ))}
                    <td>{total}</td>
                    <td>{rvu.toFixed(2)}</td>
                </tr>
            );
        });

        return (
            <>
                <h4>Total Reports: {totalReports}</h4>
                <p><strong>Total RVU:</strong> {totalRVU.toFixed(2)} [ MR = 1.4, CR/DX = 0.3, US = 0.7, MG = 0.7, CT = 1.5 ]</p>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>User</th>
                            {modalities.map(modality => (
                                <th key={modality}>{modality}</th>
                            ))}
                            <th>Total</th>
                            <th>Total RVU</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </>
        );
    };

    const transformPerformanceData = (data) => {
        const transformedData = Object.entries(data).map(([date, modalitiesData]) => ({
            date: new Date(date).toISOString().split('T')[0],
            ...modalitiesData,
        }));
        return transformedData;
    };

    const renderLineChart = (data) => (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={transformPerformanceData(data)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis domain={[0, 'dataMax + 100']} />
                <Tooltip />
                <Legend />
                {Object.keys(data[Object.keys(data)[0]]).map((key) => (
                    <Line key={key} type="monotone" dataKey={key} stroke="#8884d8" activeDot={{ r: 8 }} />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );

    return (
        <>
            {isReportsByUserModalityLoading ? (
                <div className="loading-overlay">
                    <PulseLoader color={"#1f2a4b"} />
                </div>
            ) : isReportsByUserModalityError ? (
                <div className="loading-overlay">
                    <PulseLoader color={"#1f2a4b"} />
                </div>
            ) : (
                <Container fluid>
                    <Row className="mb-3 justify-content-center">
                        <Col xs={12} sm={6} md={3}>
                            <Form.Group>
                                <Form.Label>Start Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={dateRange.startDate.toISOString().split('T')[0]}
                                    onChange={(e) => handleDateChange('startDate', new Date(e.target.value))}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={3}>
                            <Form.Group>
                                <Form.Label>End Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={dateRange.endDate.toISOString().split('T')[0]}
                                    onChange={(e) => handleDateChange('endDate', new Date(e.target.value))}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h4 className='text-center'>Study Numbers (Users)</h4>
                    <Row>
                        <Col xs={12}>
                            {reportsByUserModality && renderTable(reportsByUserModality)}
                        </Col>
                    </Row>
                    {isPerformanceLoading ? (
                        <div className="loading-overlay">
                            <PulseLoader color={"#1f2a4b"} />
                        </div>
                    ) : isPerformanceError ? (
                        <div className="loading-overlay">
                            <PulseLoader color={"#1f2a4b"} />
                        </div>
                    ) : userPerformanceData && (
                        <Row className="mt-4">
                            <Col xs={12}>
                                <h4 className='text-center'>{selectedUser} Performance</h4>
                                {renderLineChart(userPerformanceData)}
                            </Col>
                        </Row>
                    )}
                </Container>
            )}
        </>
    );
};

export default UsersAnalytics;
import { apiSlice } from "../../app/api/apiSlice"

export const feedbackApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendFeedback: builder.mutation({
            query: ({ userid, subject, message }) => ({
                url: '/backend/mail/feedback',
                method: 'POST',
                body: { userid, subject, message }
            }),
        }),
    }),
})

export const {
    useSendFeedbackMutation,
} = feedbackApiSlice

import React, { useState } from 'react';
import { Table, Container, Row, Col, Form } from 'react-bootstrap';
import PulseLoader from 'react-spinners/PulseLoader';
import { useGetReportsByModalityAndInstituteQuery } from './analyticsApiSlice';

const InstitutesAnalytics = () => {
    const calculateDefaultDates = () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
        return { startDate, endDate };
    };

    const [dateRange, setDateRange] = useState(calculateDefaultDates());

    const handleDateChange = (field, date) => {
        setDateRange(prevState => ({
            ...prevState,
            [field]: date,
        }));
    };

    const {
        data: reportsByModalityAndInstitute,
        isLoading: isReportsByModalityAndInstituteLoading,
        isError: isReportsByModalityAndInstituteError,
    } = useGetReportsByModalityAndInstituteQuery({
        startDate: dateRange.startDate.toISOString().split('T')[0],
        endDate: dateRange.endDate.toISOString().split('T')[0],
    });

    const renderTable = (data) => {
        const modalities = ['MR', 'CR/DX', 'US', 'MG', 'CT'];
        let totalReports = 0;

        const tableRows = Object.entries(data).map(([institute, modalitiesData]) => {
            const total = modalities.reduce((sum, modality) => sum + (parseInt(modalitiesData[modality]) || 0), 0);
            totalReports += total;
            return (
                <tr key={institute}>
                    <td>{institute}</td>
                    {modalities.map(modality => (
                        <td key={modality}>{modalitiesData[modality] || 0}</td>
                    ))}
                    <td>{total}</td>
                </tr>
            );
        });

        return (
            <>
                <h4>Total Reports: {totalReports}</h4>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Institute</th>
                            {modalities.map(modality => (
                                <th key={modality}>{modality}</th>
                            ))}
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </>
        );
    };

    return (
        <>
            {isReportsByModalityAndInstituteLoading ? (
                <div className="loading-overlay">
                    <PulseLoader color={"#1f2a4b"} />
                </div>
            ) : isReportsByModalityAndInstituteError ? (
                <div className="loading-overlay">
                    <PulseLoader color={"#1f2a4b"} />
                </div>
            ) : (
                <Container fluid>
                    <Row className="mb-3 justify-content-center">
                        <Col xs={12} sm={6} md={4}>
                            <Form.Group>
                                <Form.Label>Start Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={dateRange.startDate.toISOString().split('T')[0]}
                                    onChange={(e) => handleDateChange('startDate', new Date(e.target.value))}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <Form.Group>
                                <Form.Label>End Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={dateRange.endDate.toISOString().split('T')[0]}
                                    onChange={(e) => handleDateChange('endDate', new Date(e.target.value))}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h4 className="text-center">Study Numbers (Institutes)</h4>
                    {reportsByModalityAndInstitute && renderTable(reportsByModalityAndInstitute)}
                </Container>
            )}
        </>
    );
};

export default InstitutesAnalytics;
import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import usePersist from '../../hooks/usePersist';
import useTitle from '../../hooks/useTitle';
import { Container, Row, Col } from 'react-bootstrap';
import Modal from '../../components/Modal';

const Login = () => {
    useTitle('telerapp - Member Login');

    const userRef = useRef();
    const errRef = useRef();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [persist, setPersist] = usePersist();
    const [errMsg, setErrMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login, { isLoading }] = useLoginMutation();

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        if (errMsg) {
            setShowModal(true);
        }
    }, [errMsg]);

    const handleCloseModal = () => {
        setShowModal(false);
        setErrMsg('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { accessToken } = await login({ username, password }).unwrap();
            dispatch(setCredentials({ accessToken }));
            setUsername('');
            setPassword('');
            navigate('/dash/report');
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            if (errRef.current) {
                errRef.current.focus();
            }
        }
    };

    const handleUserInput = (e) => setUsername(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);
    const handleToggle = () => setPersist((prev) => !prev);

    if (isLoading)
        return (
            <div className="loading-overlay">
                <PulseLoader color={"#1f2a4b"} />
            </div>
        );

    const content = (
        <Container fluid className="login-page">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={10} md={8} lg={6} xl={4}>
                    <header className="h-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                        <div className="wrapper">
                            <h1>Member login</h1>
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-box">
                                            <input
                                                placeholder="Username"
                                                type="text"
                                                title="Username"
                                                id="username"
                                                ref={userRef}
                                                value={username}
                                                onChange={handleUserInput}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                        <div className="input-box">
                                            <input
                                                placeholder="Password"
                                                type="password"
                                                title="Password"
                                                id="password"
                                                onChange={handlePwdInput}
                                                value={password}
                                                required
                                            />
                                        </div>
                                        <div
                                            className="remember checkbox-wrapper-18"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div className="round" style={{ marginRight: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    id="persist"
                                                    title="Save credentials"
                                                    onChange={handleToggle}
                                                    checked={persist}
                                                />
                                                <label htmlFor="persist"></label>
                                            </div>
                                            Remember me
                                        </div>
                                        <button type="submit" title="Login" className="btn">
                                            Sign In
                                        </button>
                                        <div className="reg-link">
                                            <Link to="/" title="Home" className="link">
                                                Back to Home
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </header>
                </Col>
            </Row>
            <Modal show={showModal} onClose={handleCloseModal}>
                <p>{errMsg}</p>
            </Modal>
        </Container>
    );

    return content;
};

export default Login;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { PieChart, Pie, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Cell, ResponsiveContainer, LineChart, Line } from 'recharts';
import PulseLoader from 'react-spinners/PulseLoader';
import { useGetReportsByModalityAndDateRangeQuery, useGetReportsByPriorityAndDateRangeQuery, useGetApprovedReportsByModalityAndDateRangeQuery, useGetReportsByCompletedStatusAndDateRangeQuery, useGetRDSStorageInfoQuery, useGetReportsByDateAnalyticsQuery, useGetReportsByWeekPerformQuery, useGetTatAverageQuery } from './analyticsApiSlice';

const ReportsAnalytics = ({ startDate, endDate, handleDateChange }) => {
    const [reportsData, setReportsData] = useState(null);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    const {
        data: reportsByModalityAndDateRange,
        isLoading: isReportsByModalityAndDateRangeLoading,
        isError: isReportsByModalityAndDateRangeError,
    } = useGetReportsByModalityAndDateRangeQuery({ startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] });

    const {
        data: reportsByPriorityAndDateRange,
        isLoading: isReportsByPriorityAndDateRangeLoading,
        isError: isReportsByPriorityAndDateRangeError,
    } = useGetReportsByPriorityAndDateRangeQuery({ startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] });

    const {
        data: approvedReportsByModalityAndDateRange,
        isLoading: isApprovedReportsByModalityAndDateRangeLoading,
        isError: isApprovedReportsByModalityAndDateRangeError,
    } = useGetApprovedReportsByModalityAndDateRangeQuery({ startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] });

    const {
        data: reportsByCompletedStatusAndDateRange,
        isLoading: isReportsByCompletedStatusAndDateRangeLoading,
        isError: isReportsByCompletedStatusAndDateRangeError,
    } = useGetReportsByCompletedStatusAndDateRangeQuery({ startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] });

    const {
        data: reportsByDate,
        isLoading: isReportsByDateLoading,
        isError: isReportsByDateError,
    } = useGetReportsByDateAnalyticsQuery({ startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] });

    const {
        data: RDSStorageInfo,
        isLoading: isRDSStorageInfoLoading,
        isError: isRDSStorageInfoError,
    } = useGetRDSStorageInfoQuery();

    const {
        data: reportsByWeek,
        isLoading: isReportsByWeekLoading,
        isError: isReportsByWeekError,
    } = useGetReportsByWeekPerformQuery({ startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] });

    const {
        data: reportsByTATAverage,
        isLoading: isReportsByTATAverageLoading,
        isError: isReportsByTATAverageError,
    } = useGetTatAverageQuery({ startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] });

    useEffect(() => {
        const fetchReportsData = async () => {
            try {
                const modalityData = await reportsByModalityAndDateRange;
                const priorityData = await reportsByPriorityAndDateRange;
                const approvedData = await approvedReportsByModalityAndDateRange;
                const completedStatusData = await reportsByCompletedStatusAndDateRange;
                const tatAverageData = await reportsByTATAverage;

                const transformData = (data) => {
                    if (!data) return [];
                    return Object.keys(data).map(key => ({ name: key, value: parseInt(data[key]) }));
                };

                setReportsData({
                    modalityData: transformData(modalityData),
                    priorityData: transformData(priorityData),
                    approvedData: transformData(approvedData),
                    completedStatusData: transformData(completedStatusData),
                    reportsByDateData: transformData(reportsByDate),
                    reportsByWeekData: transformData(reportsByWeek),
                    tatAverageData: transformData(tatAverageData),
                });
            } catch (error) {
                console.error('Error fetching reports data:', error);
                setReportsData(null);
            }
        };

        fetchReportsData();
    }, [startDate, endDate, reportsByModalityAndDateRange, reportsByPriorityAndDateRange, approvedReportsByModalityAndDateRange, reportsByCompletedStatusAndDateRange, reportsByDate, reportsByWeek, reportsByTATAverage]);

    return (
        <>
            {(isApprovedReportsByModalityAndDateRangeLoading || isRDSStorageInfoLoading || isReportsByCompletedStatusAndDateRangeLoading || isReportsByModalityAndDateRangeLoading || isReportsByPriorityAndDateRangeLoading || isReportsByDateLoading || isReportsByWeekLoading || isReportsByTATAverageLoading) ? (
                <div className="loading-overlay">
                    <PulseLoader color={"#1f2a4b"} />
                </div>) :
                (isApprovedReportsByModalityAndDateRangeError || isRDSStorageInfoError || isReportsByCompletedStatusAndDateRangeError || isReportsByModalityAndDateRangeError || isReportsByPriorityAndDateRangeError || isReportsByDateError || isReportsByWeekError || isReportsByTATAverageError) ? (
                    <div className="loading-overlay">
                        <PulseLoader color={"#1f2a4b"} />
                    </div>) :
                    (<Container fluid>
                        <Row className="mb-3 justify-content-center">
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group>
                                    <Form.Label>Start Date:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate.toISOString().split('T')[0]}
                                        onChange={(e) => handleDateChange('start', new Date(e.target.value))}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group>
                                    <Form.Label>End Date:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate.toISOString().split('T')[0]}
                                        onChange={(e) => handleDateChange('end', new Date(e.target.value))}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={6} md={4}>
                                {RDSStorageInfo && (
                                    <Form.Group>
                                        <Form.Label>RDS Storage Info:</Form.Label>
                                        <p><strong>Free Storage Space:</strong> {RDSStorageInfo?.freeStorageSpace} GB / <strong>Allocated Storage:</strong> {RDSStorageInfo?.allocatedStorage} GB</p>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>

                        <Row className="mb-3">

                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                                <Card className="h-100 border-0">
                                    <Card.Body className="card-body-analyt">
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart data={reportsData?.priorityData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar dataKey="value" fill="#82ca9d" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <Legend layout="vertical" align="left" verticalAlign="middle" />
                                        <u><p className="small-text">Study Priority</p></u>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                                <Card className="h-100 border-0">
                                    <Card.Body className="card-body-analyt">
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart data={reportsData?.approvedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar dataKey="value" fill="#82ca9d" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <Legend layout="vertical" align="left" verticalAlign="middle" />
                                        <u><p className="small-text">Study Numbers (Approved)</p></u>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                                <Card className="h-100 border-0">
                                    <Card.Body className="card-body-analyt">
                                        <ResponsiveContainer width="100%" height={300}>
                                            <PieChart>
                                                <Pie
                                                    data={reportsData?.completedStatusData}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={60}
                                                    innerRadius={40}
                                                    label={(entry) => entry.value}
                                                >
                                                    {reportsData?.completedStatusData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                                <Legend
                                                    layout="horizontal"
                                                    align="center"
                                                    verticalAlign="bottom"
                                                    wrapperStyle={{ bottom: 0, left: 0, right: 0, padding: 10 }}
                                                    payload={reportsData?.completedStatusData.map((entry, index) => ({
                                                        id: entry.name,
                                                        type: "square",
                                                        value: `${entry.name}: ${entry.value}`,
                                                        color: COLORS[index % COLORS.length],
                                                    }))}
                                                />
                                            </PieChart>
                                        </ResponsiveContainer>
                                        <Legend layout="vertical" align="left" verticalAlign="middle" />
                                        <u><p className="small-text">Study Numbers by Status</p></u>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                                <Card className="h-100 border-0">
                                    <Card.Body className="card-body-analyt">
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart data={reportsData?.tatAverageData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar dataKey="value" fill="#8884d8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <Legend layout="vertical" align="left" verticalAlign="middle" />
                                        <u><p className="small-text">Average TAT by Modality (Minutes)</p></u>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                                <Card className="h-100 border-0">
                                    <Card.Body className="card-body-analyt">
                                        <ResponsiveContainer width="100%" height={300}>
                                            <PieChart>
                                                <Pie
                                                    data={reportsData?.modalityData}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={60}
                                                    innerRadius={40}
                                                    label={(entry) => entry.value}
                                                >
                                                    {reportsData?.modalityData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                                <Legend
                                                    layout="horizontal"
                                                    align="center"
                                                    verticalAlign="bottom"
                                                    wrapperStyle={{ bottom: 0, left: 0, right: 0, padding: 10 }}
                                                    payload={reportsData?.modalityData.map((entry, index) => ({
                                                        id: entry.name,
                                                        type: "square",
                                                        value: `${entry.name}: ${entry.value}`,
                                                        color: COLORS[index % COLORS.length],
                                                    }))}
                                                />
                                            </PieChart>
                                        </ResponsiveContainer>
                                        <Legend layout="vertical" align="left" verticalAlign="middle" />
                                        <u><p className="small-text">Study Distribution by Modality</p></u>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} md={6} lg={4} className="mb-4">
                                <Card className="h-100 border-0">
                                    <Card.Body className='card-body-analyt'>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <LineChart data={reportsData?.reportsByDateData || []}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                        <Legend layout="vertical" align="left" verticalAlign="middle" />
                                        <u><p className="small-text">Reports Over Time</p></u>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} md={6} lg={4} className="mb-4">
                                <Card className="h-100 border-0">
                                    <Card.Body className='card-body-analyt'>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={reportsData?.reportsByWeekData || []} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar dataKey="value" fill="#82ca9d" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <Legend layout="vertical" align="left" verticalAlign="middle" />
                                        <u><p className="small-text">Weekly Performance</p></u>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>

                    </Container>
                    )}
        </>
    );
};

export default ReportsAnalytics;